












import Vue from 'vue';
import FloatButton from "./components/FloatButton.vue";
import License from "./components/License.vue"
// import Header from '@/components/Header.vue'
import axios from 'axios'

export default Vue.extend({
  name: 'App',
  components: {FloatButton,License},
  data: () => ({
    drow:false,
    connected:false,
    withsidemenu:true,
  }),
 
  computed: {
      lang: function(){
        if(this.$store.state.deflang == 'en'){
          return this.$store.state.lang.en;
        }else{
          return this.$store.state.lang.ar;
        }
      },
  },
  
  methods:{
    handleUpdate: function () {
      //this.connected = true;
    },
    getCookies(){
      if(this.connected == true) return false;
      let x = this.$cookies.get(this.$store.state.COOKIEPhase);
      if(x == null){
        
        this.connected = false;
        if(this.$route.name != 'Login')
          this.$router.push({name: "Login"})
      }
      else{
        this.connected = true;
        if(this.$route.name == 'Login')
          this.$router.push({name: 'Home'});
      }
    },
    checkLicense(){
      axios.get(
        this.$store.state.SAMCOTEC.r_path
      ).then( (response) => {
        
        const res = response.data;
        if(res.error.number == 555){
          // document.getElementById('apply_license').style.display = "block";
          // this.license = res.results.data[1];
          // this.settings = res.results.data[0];
        }
      })
    },
   
  },
  created() {
    
    document.title = this.$store.state.SystemName;
    //this.getCookies()
  },
});
